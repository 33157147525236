<script lang="ts">
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

import BlazonBig from '@/assets/images/blazon-big.svg'

export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean
}

export default defineComponent({
  name: 'Preloader', // eslint-disable-line
  components: {
    BlazonBig
  },
  setup () {
    const visible = ref(true)

    return {
      visible
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <Transition>
      <div v-if="visible" id="app-loading">
        <BlazonBig class="blazon" />
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
#app-loading {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  @include paper-background;
}

.frame {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  svg {
    margin: rem(8px);
    opacity: 0.5;
  }
}

.blazon {
  position: relative;
  width: rem(164px);
  height: rem(164px);
}
</style>
